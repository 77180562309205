[data-theme="default"] .newforma-projectsContainer {
  --hiliteColor: #0277D4;
  --radioCheckedColor: #0277D4;
  --radioUncheckedColor: #888;
  --selectedColor: black;
  --unselectedColor: #616161;
  --labelHoverBackground: #E7EAF1;
  --labelHiliteColor: #fff1c9;
}
[data-theme="dark"] .newforma-projectsContainer {
  --hiliteColor: #76b6e6;
  --radioCheckedColor: #76b6e6;
  --radioUncheckedColor: #adadad;
  --selectedColor: white;
  --unselectedColor: #adadad;
  --labelHoverBackground: #595858;
  --labelHiliteColor: #fff1c9;
}
.newforma-projectsContainer .nf-hidden {
  visibility: hidden;
}
.newforma-projectsContainer .rbLabel {
  color: var(--hiliteColor);
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
}
.newforma-projectsContainer .rbItemContainer:hover {
  background-color: var(--labelHoverBackground);
}
.newforma-projectsContainer label.rbItemContainer {
  box-sizing: border-box;
  max-width: 100%;
  display: flex;
  column-gap: 5px;
  align-items: center;
  font-size: 14px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  transition: background-color 0.15s ease;
  padding: 6px 4px;
  margin-right: 12px;
  border-radius: 2px;
}
.newforma-projectsContainer label.rbItemContainer > span {
  flex: 1;
  min-width: 0;
}
.newforma-projectsContainer input.rbRadioButton {
  flex-shrink: 0;
  appearance: none;
  display: grid;
  height: 1rem;
  width: 1rem;
  border: 1px solid var(--radioUncheckedColor);
  border-radius: 50%;
}
.newforma-projectsContainer input.rbRadioButton:checked {
  border-color: var(--radioCheckedColor);
}
.newforma-projectsContainer input.rbRadioButton:checked::before {
  content: "";
  display: block;
  place-self: center;
  width: 0.6rem;
  height: 0.6rem;
  background-color: var(--radioCheckedColor);
  border-radius: 50%;
}
.newforma-projectsContainer .rbSelectedLabel {
  color: var(--selectedColor);
}
.newforma-projectsContainer .rbUnselectedLabel {
  color: var(--unselectedColor);
}
.newforma-projectsContainer .rbHilite {
  background-color: var(--labelHiliteColor);
}
.newforma-radioButtonLabel {
  --dark-hilite-color: #76b6e6;
  --light-hilite-color: #0277D4;
}
.newforma-radioButtonLabel .rb-pdt10 {
  padding-top: 10px;
}
.newforma-radioButtonLabel .light-theme .rbClear {
  color: var(--light-hilite-color);
  font-size: 12px;
  font-weight: 400;
}
.newforma-radioButtonLabel .dark-theme .rbClear {
  color: var(--dark-hilite-color);
  font-size: 12px;
  font-weight: 400;
}
